import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'

import { routers } from './constants'
import { withProviders } from './providers'

const routes = [
    { Component: React.lazy(() => import('./routes/StepOne')), path: routers.STEP_ONE },
    { Component: React.lazy(() => import('./routes/StepTwo')), path: routers.STEP_TWO },
    { Component: React.lazy(() => import('./routes/StepThree')), path: routers.STEP_THREE },
    { Component: React.lazy(() => import('./routes/StepFour')), path: routers.STEP_FOUR },
]

const App = () => {
    const state = useSelector((state) => state)
    const language = useSelector((state) => state.language)
    useEffect(() => {
        console.log(state)
    }, [state])
    return (
        <div className='App'>
            <div className='container'>
                <div className='stub-header'></div>
                <Routes>
                    {routes.map(({ path, Component }, index) => (
                        <Route
                            path={path}
                            key={`route-${index}`}
                            element={<Component language={language} />}
                        />
                    ))}
                    {/* <Route path='*' element={<Navigate to='/' />} /> */}
                </Routes>
            </div>
        </div>
    )
}

export default withProviders(App)
