import { createSlice } from '@reduxjs/toolkit'
import { setField } from '../actions/shared'
import { createArray, filterObjectByKeys } from '../../utils/common.utils'
import { floorCoveringConfig } from '../../configs/floor.config'
import { wallCoveringConfig } from '../../configs/wall.config'

const roomState = {
    floor: 0, // Пол
    wall: 0, // Стены
    ceiling: 0, // Потолок
    furniture: 0, // мебель
    floor_area: 1, // площадь пола
    wall_height: 1.5, // высота стен
    floor_surface: 0, // поверхность пола (smooth, not_perfectly_smooth, seriously_damaged)
    // floor_damage_level: 10, // пуровень повреждения пола
    laminate: 0, //  Ламинат
    parquet: 0, //  Паркет
    carpet: 0, //  Ковролин
    tile: 0, //  Плитка
    baseboard: 'not', // Плинтус (по умолчанию нет)
    wall_covering: '', // покрытие стен,
    ceiling_skirting_board: 0, // потолочный плинтус (0,1)
    paint: 0, // Покрасить
    wallpaper: 0, // Обои
    decorative_plaster: 0, // декоративная штукатурка
    wall_tiles: 0, // Плитка стен
    wall_panels: 0, // Панели стен
    wall_damage: 0, // Повреждение стены
    sockets: 0, // розетки
    switches: 0, // выключатели
    lamps: 0, //  лампы
    doors: 0, // двери
    ceiling_covering: '', // покрытие потолка (paint,suspended,stretch, armstrong)
}

const initialState = {
    living_room: 0, // Гостиная
    bedroom: 0, // Спальня
    hallway: 0, // Коридор
    kitchen: 0, // Кухня
    bathroom: 0, // Ванная
    cabinet: 0, // Кабинет

    step_room: 0, // шаг помещения (пример - у нас выбрано 5 кабинетов, по умолчанию начинаем с первого)
    step_construction: 0, // шаг конструкции (по умолчанию начинаем с первого)
    room_to_fill: '', // помещения для заполнения (хранит ключ помещения которое будем наполнять данными)
    construction_to_fill: 'сhoose_сonstruction', // конструкция для заполнения (хранит ключ конструкции которое будем наполнять данными) (сhoose_сonstruction, floor, wall, ceiling)
    selected_construction: [], // выбранные конструкции (по окончанию заполнения данных о конструкции удаляем ее отсюда )

    ready_rooms: [], // готовые комнаты
    selected_rooms: {}, // выбранные помещения
}

export const roomsSlice = createSlice({
    name: 'rooms',
    initialState,
    reducers: {
        chooseRoom: setField,
        nextAction: (state) => {
            // получаем текущие помещение
            const roomToFill = state.selected_rooms[state.room_to_fill]
            // фильтрум выбранные конструкции которые мы выбрали для ремонта (получаем массив ключей кроме текущей конструкции)
            const filteredSelectedConstruction = state.selected_construction.filter(
                (key) => key !== state.construction_to_fill
            )

            if (!filteredSelectedConstruction.length) {
                // если в отфельтруемом массиве конструкций нет ключей
                // тогда проверяем какое количество комнат мы заполнили текущего помещения

                if (roomToFill.length === +state.step_room + 1) {
                    // если количество комнат совпадает с выбранной комнатой

                    // получаем готовые комнаты с текущей
                    const readyRooms = [...state.ready_rooms, state.room_to_fill]
                    // получаем ключи выбранных помещений фильтруя уже готовые помещения
                    const nextKeys = Object.keys(state.selected_rooms).filter(
                        (key) => !readyRooms.includes(key)
                    )
                    // обновляем готовые помещения
                    state.ready_rooms = readyRooms

                    if (!nextKeys.length) {
                        //если ключей нет - пока ничего не делаем
                        state.construction_to_fill = 'ready'
                        
                        return
                    }
                    // если если ключи помещений - переходим на новое помещение
                    state.room_to_fill = nextKeys[0]
                    state.step_room = 0
                    state.step_construction = 0
                    state.construction_to_fill = 'сhoose_сonstruction'
                } else {
                    // если количество комнат не совпадает с выбранной комнатой тогда переходим к следующей
                    state.step_construction = 0
                    state.step_room = state.step_room + 1
                    state.construction_to_fill = 'сhoose_сonstruction'
                }
            } else {
                // если в отфельтруемом массиве конструкций есть конструкци(и\я) - выставляем ключ следующей конструкции
                state.step_construction = 0
                state.construction_to_fill = filteredSelectedConstruction[0]
            }

            // обновляем выбранные конструкции на отфильтрованный массив выбранных конструкций
            state.selected_construction = filteredSelectedConstruction
        },
        setStepConstruction: (state, { payload }) => {
            state.step_construction = payload
        },
        setConstructionToFill: (state, { payload }) => {
            state.construction_to_fill = payload.construction_to_fill
            state.selected_construction = payload.selected_construction
        },
        setFieldSelectedRoom: (state, { payload: { key, value } }) => {
            state.selected_rooms[state.room_to_fill][state.step_room][key] = value
        },
        setEntireAreaToBeCovered: (state, { payload }) => {
            // установить покрытию всю площадь
            const room = state.selected_rooms[state.room_to_fill][state.step_room]
            room[payload] = +room[payload] === +room.floor_area ? 0 : +room.floor_area
            floorCoveringConfig.forEach((key) => {
                key !== payload && (room[key] = 0)
            })
        },
        setNumberOfRooms: (state, { payload }) => {
            const value = payload.value.replace(/^([^1-9])/g, '')
            state[payload.key] = +value <= 10 ? value : '10'
        },
        setNumberOfMounting: (state, { payload }) => {
            const value = payload.value.replace(/\D/g, '')
            const room = state.selected_rooms[state.room_to_fill][state.step_room]
            room[payload.key] = +value <= 100 ? +value : 100
        },
        setSelectedRooms: (state) => {
            const filtered = filterObjectByKeys(
                ['living_room', 'bedroom', 'hallway', 'kitchen', 'bathroom', 'cabinet'],
                state
            )
            Object.entries(filtered).forEach(([key, value]) => {
                if (+value > 0 && state.selected_rooms[key] === undefined) {
                    state.selected_rooms[key] = createArray(+value).map(() => roomState)
                }
            })

            if (!state.room_to_fill) {
                state.room_to_fill = Object.keys(state.selected_rooms)[0]
            }
        },
        setWallCovering: (state, action) => {
            const { key, value } = action.payload
            const room = state.selected_rooms[state.room_to_fill][state.step_room]

            if (value === 'all') {
                room[key] = room[key] === 4 ? 0 : 4
                wallCoveringConfig.forEach((item) =>
                    item.key !== key ? (room[item.key] = 0) : null
                )
            } else {
                const totalAmount = wallCoveringConfig.reduce(
                    (acc, elem) => (elem.key === key ? acc : acc + +room[elem.key]),
                    0
                )
                if (room[key] === +value) {
                    room[key] = room[key] - 1
                } else {
                    room[key] = totalAmount + +value <= 4 ? +value : 4 - totalAmount
                }
            }
        },
    },
})

export const {
    nextAction,
    chooseRoom,
    setNumberOfRooms,
    setSelectedRooms,
    setConstruction,
    setConstructionToFill,
    setStepConstruction,
    setFieldSelectedRoom,
    setEntireAreaToBeCovered,
    setNumberOfMounting,
    setWallCovering,
} = roomsSlice.actions

export default roomsSlice.reducer
