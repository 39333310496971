import { configureStore } from '@reduxjs/toolkit'

import languageSlice from './features/language'
import roomsSlice from './features/rooms'
import commonSlice from './features/common'

export const store = configureStore({
    reducer: {
        common: commonSlice,
        rooms: roomsSlice,
        language: languageSlice,
    },
})