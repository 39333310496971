import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    repair_placement: 'Выберите где нужен ремонт',
    house: 'Дом',
    flat: 'Квартира',
    office: 'Офис',
    electricity: 'Есть электричество',
    water: 'Вода',
    toilet: 'Туалет',
    garbage: 'Вывоз мусора',
    heating: 'Отопление',
    storey: 'Какой этаж',
    yes: 'Да',
    not: 'Нет',
    further: 'Далее',
    living_room: 'Гостиная',
    bedroom: 'Спальня',
    hallway: 'Коридор',
    kitchen: 'Кухня',
    bathroom: 'Ванная',
    cabinet: 'Кабинет',
    in_living_room: 'гостиной ',
    in_bedroom: 'спальне',
    in_hallway: 'коридоре',
    in_kitchen: 'кухни',
    in_bathroom: 'ванной',
    in_cabinet: 'кабинете',
    quantity: 'Количество',
    floor: 'Пол',
    wall: 'Стены',
    ceiling: 'Потолок',
    floor_area: 'S пола',
    wall_height: 'H стен',
    laminate: 'Ламинат',
    parquet: 'Паркет',
    carpet: 'Ковролин',
    tile: 'Плитка',
    whole: 'Весь',
    smooth: 'ровный',
    sockets: 'Розетки',
    switches: 'Выключатели',
    lamps: 'Лампы',
    doors: 'Двери',
    suspended: 'Подвесной',
    stretch: 'Натяжной',
    armstrong: 'Армстронг',
    paint: 'Покрасить',
    wallpaper: 'Обои',
    panels: 'Панели',
    all: 'Все',
    ceiling_skirting_board: 'плинтус потолочный',
    installation_is_needed: 'Нужен монтаж',
    decorative_plaster: 'Декоративная штукатурка',
    they_have_damage: 'Имеют повреждения',
    not_perfectly_smooth: 'не идеально ровный',
    seriously_damaged: 'серьезно поврежден',
    what_needs_to_be_done_in: 'Что нужно сделать в',
    need_baseboard: 'Нужен плинтус',
    what_does_it_look_like_now: 'Как сейчас выглядит',
    select_the_fields_where_repairs_are_needed: 'Выберите поля, где нужен ремонт',
    what_should_be_put_on_the_floor: 'Что нужно положить на пол ',
    furniture: 'Есть ли в комнате мебель',
    working_night: 'Возможна работа ночью',
    parking: 'Есть ли парковка',
    get_calculation: 'Получить расчет'
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {},
})

export default languageSlice.reducer
