export const wallDamageConfig = ['smooth', 'not_perfectly_smooth', 'they_have_damage']
export const mountingConfig = ['ceiling_skirting_board', 'sockets', 'switches', 'lamps', 'doors']
export const numberOfWalls = [1, 2, 3]
export const wallCoveringConfig = [
    { key: 'paint', lang_key: 'paint' },
    { key: 'wallpaper', lang_key: 'wallpaper' },
    { key: 'wall_tiles', lang_key: 'tile' },
    { key: 'decorative_plaster', lang_key: 'decorative_plaster' },
    { key: 'wall_panels', lang_key: 'panels' },
]
