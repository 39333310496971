export const isNumeric = (value) => !isNaN(value) && !isNaN(parseFloat(value))

export const createArray = (size) =>
    Array(size)
        .fill(0)
        .map((_, i) => i)

export const filterObjectByKeys = (keys, obj) => {
    return Object.keys(obj)
        .filter((key) => keys.includes(key))
        .reduce((acc, key) => {
            acc[key] = obj[key]
            return acc
        }, {})
}
export const deletKeysInObject = (keys, obj) => {
    return Object.keys(obj)
        .filter((key) => !keys.includes(key))
        .reduce((acc, key) => {
            acc[key] = obj[key]
            return acc
        }, {})
}
