import { createSlice } from '@reduxjs/toolkit'
import { setField } from '../actions/shared'

const initialState = {
    // house|flat|office
    repair: '',

    // 0 - false; 1 - true
    electricity: 0,
    water: 0,
    toilet: 0,
    garbage: 0,
    heating: 0,
    parking: 0,
    working_night: 0,

    // от 1 до 100
    storey: 0,
}

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setFieldCommon: setField,
    },
})

export const { setFieldCommon } = commonSlice.actions

export default commonSlice.reducer
